export default function Tiempo(props) {
    const {activeSubButton, handleSubButtonClick} = props
    return (
        <div className="md:flex md:justify-center xl:justify-end ">
              <div className="flex text-xs mb-5 justify-center md:w-2/3 md:max-w-[400px]">
                <button
                  className={`py-3 w-full mr-2 rounded-md transition-all duration-200 ${
                    activeSubButton === "UltimaSemana"
                      ? "text-white bg-[#198cff] !important"
                      : "bg-gray-200 hover:bg-[#e9f3fd] hover:text-[#198cff]"
                  }`}
                  onClick={() => handleSubButtonClick("UltimaSemana")}
                >
                  Esta Semana
                </button>
                <button
                  className={`px-2 py-3 mr-2 w-full rounded-md transition-all duration-200 ${
                    activeSubButton === "UltimaMes"
                      ? "text-white bg-[#198cff]"
                      : "bg-gray-200 hover:bg-[#e9f3fd] hover:text-[#198cff]"
                  }`}
                  onClick={() => handleSubButtonClick("UltimaMes")}
                >
                  Últimos 30 dias
                </button>
                <button
                  className={`py-3 w-80 rounded-md transition-all duration-200 ${
                    activeSubButton === "UltimoAnio"
                      ? "text-white bg-[#198cff]"
                      : "bg-gray-200 hover:bg-[#e9f3fd] hover:text-[#198cff]"
                  }`}
                  onClick={() => handleSubButtonClick("UltimoAnio")}
                >
                  Este Año
                </button>
              </div>
            </div>
    )
}