import { useEffect, useRef, useState } from "react";
import Menu from "../components/Menu";
import Photo from "../components/Photo";
import { StatCard } from "../components/StatCard";
import {
  Save2,
  PencilSquare,
  Shop,
  EnvelopeFill,
  PersonVcardFill,
  CashCoin,
  PersonFill,
} from "react-bootstrap-icons";
import SessionOut from "../components/SessionOut";
import { fetchWeeklyData } from "../utils/restaurantDataGetter";
import Modal from "../utils/Modal";

const getUserData = async (token, setSessionExpired) => {
  try {
    const response = await fetch("https://tippitqr.com/userdb/token", {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 401) {
      setSessionExpired(true);
      return;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error accediendo a los datos del user:", error);
  }
};

export default function MyProfile() {
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const token = sessionStorage.getItem("token");
  const rol = sessionStorage.getItem("rol");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [cuitInput, setCuitInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");


  const [profileData, setProfileData] = useState({
    nombre: "",
    username: "",
    cuit: "",
    address: "",
    alias: "",
    email: "",
    photo: "",
  });
  const photoRef = useRef(profileData);
  const [mozoData, setMozoData] = useState({
    tips: 0,
    promedio: 0,
    cant: 0,
  });

  useEffect(() => {
    const fetch = async () => {
      const data = await getUserData(token, setSessionExpired);
      if (data) {
        setProfileData(data);
      }
      if (rol === "Mozo") {
        const weeklyTips = await fetchWeeklyData(token, setSessionExpired);
        setMozoData(weeklyTips);
      }
    };
    fetch();
  }, [token]);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async (token) => {
    setIsEditing(false);
    console.log(photoRef.current.src);
    const formData = new URLSearchParams();

    formData.append("nombre", profileData.nombre);
    formData.append("photo", photoRef.current.src);
    formData.append("alias", profileData.alias);
    formData.append("address", profileData.address);

    try {
      const response = await fetch("https://tippitqr.com/userdb/update", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      });

      if (response.status === 401) {
        setSessionExpired(true);
        return;
      }
      setIsEditing(!isEditing);
    } catch (error) {
      console.error("Error accediendo a los datos del user:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: value,
    }));

    if (name === "cuit") {
      setCuitInput(value);
    }
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          "https://tippitqr.com/listar_roles/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Error al obtener los roles");
        }
        const data = await response.json();
        console.log("Data:", data); // Verifica aquí
        setRoles(Array.isArray(data.roles) ? data.roles : []);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoles();
  }, [token]);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleAgregarClick = async () => {
    if (!selectedRole || !cuitInput) {
      setErrorMessage("Por favor, completa todos los campos.");
      return;
    }

    const params = new URLSearchParams();
    params.append("cuit", cuitInput);
    params.append("rol", selectedRole);

    // Imprimir parámetros para depuración
    console.log("Parámetros que se van a enviar:");
    console.log("CUIT:", cuitInput);
    console.log("Rol:", selectedRole);
    console.log("Cuerpo de la solicitud:", params.toString());

    try {
      const response = await fetch(
        "https://tippitqr.com/vincular_mozo/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: params.toString(),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Respuesta de la API:", result);
        setSuccessMessage("Personal agregado exitosamente.");
        setErrorMessage("");
      } else {
        console.error("Error en la solicitud:", response.status);
        setSuccessMessage("");
        const errorBody = await response.text();
        const errorJson = JSON.parse(errorBody);
        setErrorMessage(
          errorJson.error ||
          "Hubo un error al procesar la solicitud. Inténtelo nuevamente."
        );
      }
    } catch (error) {
      console.error("Error de red:", error);
      setSuccessMessage("");
      setErrorMessage("Error de red. Por favor, intente nuevamente más tarde.");
    }
  };

  const fetchPersonal = async (token, setSessionExpired, cuit) => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    try {
      const response = await fetch(
        `https://tippitqr.com/empleado/dato/vinculo?cuit_empleado=${cuit}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 401) {
        setSessionExpired(true);
        return;
      }

      const data = await response.json();

      if (data) {
        console.log("Response Data:", data);
        setModalContent(data);
      }

      return data;
    } catch (error) {
      console.error("Error accediendo a los datos del personal:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleBuscarClick = async () => {
    const cuitInput = document.getElementById("cuitInput").value;
    setErrorMessage("");
    if (cuitInput) {
      const data = await fetchPersonal(token, setSessionExpired, cuitInput);

      if (data && data.detail !== "Empleado no encontrado") {
        console.log("Modal Content:", data);
        setModalContent(data);
        setIsModalOpen(true);
      } else if (data && data.detail === "Empleado no encontrado") {
        setModalContent("");
      }
    }
  };

  const handleClose = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setSelectedRole("")
  }

  return (
    <div className="flex flex-col lg:flex-row h-full lg:h-screen bg-gray-100">
      {sessionExpired && <SessionOut />}
      <Menu rol={rol} token={token} />
      <main className="flex-1 p-4 md:p-8">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 md:mb-8">
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">
            Mi Perfil
          </h2>
          {!isEditing ? (
            <button
              className="mt-4 md:mt-0 px-3 py-2 border border-gray-300 rounded-md flex items-center hover:bg-gray-100"
              onClick={handleEdit}
              hidden
            >
              <PencilSquare className="mr-2 h-4 w-4" /> Editar Perfil
            </button>
          ) : (
            <button
              className="mt-4 md:mt-0 px-3 py-2 border border-gray-300 rounded-md flex items-center hover:bg-gray-100"
              onClick={() => handleSave(token)}
              hidden
            >
              <Save2 className="mr-2 h-4 w-4" /> Guardar Cambios
            </button>
          )}
        </div>

        <div className="flex flex-col gap-6 mb-4 md:mb-8">
          <div className="flex flex-col lg:flex-row gap-6">
            <StatCard>
              {isEditing && <Photo photoRef={photoRef} />}
              <div className="flex flex-col items-center justify-center gap-4 h-full">
                <div
                  className={`rounded-full overflow-hidden ${isEditing ? "w-0 h-0" : "w-32 h-32 lg:w-64 lg:h-64"
                    }`}
                >
                  <img
                    hidden={isEditing}
                    ref={photoRef}
                    src={profileData.photo}
                    alt="Foto de perfil"
                    className="rounded-full object-cover w-full h-full"
                  />
                </div>
                {!isEditing && (
                  <div className="text-center lg:text-left flex flex-col items-center">
                    <h2 className="text-2xl md:text-5xl font-bold hover:text-pink">
                      {profileData.nombre}
                    </h2>
                    <span className="inline-block bg-gray-200 group-hover:bg-pink rounded-full px-4 py-1 md:px-6 md:py-2 text-lg md:text-2xl font-semibold text-gray-700 mt-1">
                      {profileData.rol}
                    </span>
                  </div>
                )}
              </div>
            </StatCard>

            <StatCard
              title={"Informacion Personal"}
              className="w-full lg:w-2/3 flex flex-col justify-center"
            >
              <div className="flex flex-col gap-10 text-lg md:text-xl">
                <div className="flex-grow flex items-center">
                  <PersonFill className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                  {isEditing ? (
                    <input
                      name="nombre"
                      value={profileData.nombre}
                      onChange={handleChange}
                      className="border rounded px-2 py-1"
                    />
                  ) : (
                    <span>{profileData.nombre}</span>
                  )}
                </div>

                <div className="flex-grow flex items-center">
                  <EnvelopeFill className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                  <span>{profileData.email}</span>
                </div>

                <div className="flex-grow flex items-center">
                  <PersonVcardFill className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                  <span>{profileData.cuit}</span>
                </div>
                {profileData.rol === "Restaurante" ? (
                  <div className="flex-grow flex items-center">
                    <Shop className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                    {isEditing ? (
                      <input
                        name="address"
                        value={profileData.address}
                        onChange={handleChange}
                        className="border rounded px-2 py-1"
                      />
                    ) : (
                      <span>{profileData.address}</span>
                    )}
                  </div>
                ) : (
                  <div className="flex-grow flex items-center">
                    <CashCoin className="mr-2 h-7 w-7 md:h-9 md:w-9 text-gray-500 group-hover:text-pink-500" />
                    {isEditing ? (
                      <input
                        name="alias"
                        value={profileData.alias}
                        onChange={handleChange}
                        className="border rounded px-2 py-1"
                      />
                    ) : (
                      <span>{profileData.alias}</span>
                    )}
                  </div>
                )}
              </div>
            </StatCard>



            {profileData.rol === "Mozo" && (
              <div className="grid grid-row-3 gap-6">
                <StatCard title="Total Propinas">
                  <div className="text-2xl font-bold">$ {mozoData.tips}</div>
                  <p className="text-xs text-gray-500">Últimos 7 días</p>
                </StatCard>
                <StatCard title="Calificación Promedio">
                  <div className="text-2xl font-bold">
                    {mozoData.promedio}
                    <span className="text-pink-500 ml-1">★</span>
                  </div>
                  <p className="text-xs text-gray-500">
                    Basado en las reseñas de esta semana
                  </p>
                </StatCard>
                <StatCard title="Mesas Atendidas">
                  <div className="text-2xl font-bold">{mozoData.cant}</div>
                  <p className="text-xs text-gray-500">En esta semana</p>
                </StatCard>
              </div>
            )}
          </div>
        </div>
        {profileData.rol === "Restaurante" && (
          <StatCard title="Agregar Nuevo Personal">
            <div className="text-1xl mt-2 max-w-50 mx-auto font-bold flex items-center">
              <label htmlFor="cuitInput" className="mr-2">
                CUIT/CUIL/DNI:
              </label>
              <input
                id="cuitInput"
                type="text"
                value={cuitInput}
                onChange={(e) => setCuitInput(e.target.value)}
                className="border rounded px-2 py-1 flex-grow"
              />
              <button
                className="transition:all 
                            duration-300 
                            ease-in-out 
                            w-full
                            text-sm 
                            px-2 
                            py-1.5
                            ml-2 
                          text-white 
                          font-semibold 
                          bg-[#FF5EA3] 
                          rounded-md 
                          hover:bg-[#FF7DF2] 
                          hover:scale-105"
                onClick={handleBuscarClick}
              >
                Buscar
              </button>
            </div>
            {modalContent === "" && (<p className="text-red-500 mt-2 text-center">Este CUIT no se encuentra registrado en la plataforma aun</p>)}
          </StatCard>
        )}

        <div id="modal-root"></div>
        <Modal isOpen={isModalOpen} onClose={() => { handleClose(); setIsModalOpen(false) }}>
          {modalContent ? (
            <div className="pt-1 px-4 w-[450px]">
              <h1 className="text-2xl font-bold mt-1 mb-2 text-center">
                Sumar Personal al Restaurante
              </h1>
              <div className="flex">
                <div className="flex-shrink-0 w-1/2 p-4">
                  <img
                    src={modalContent.foto}
                    alt=""
                    className="object-cover rounded-full w-[140px] h-[140px] mx-auto mt-2 border-pink-500 border-4"
                  />
                </div>
                <div className="w-1/2 p-4 flex flex-col justify-between">
                  <div>
                    <h3 className="text-xl font-semibold mb-2">
                      {modalContent.nombre}
                    </h3>
                    <p className="text-lg mb-4">CUIT: {cuitInput}</p>
                    <select
                      className="border rounded px-3 py-1 w-full"
                      value={selectedRole}
                      onChange={handleRoleChange}
                    >
                      <option value="" disabled>
                        ELEGIR ROL
                      </option>
                      {roles.map((role, index) => (
                        <option key={index} value={role.nombre_rol}>
                          {role.nombre_rol}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      className="transition:all 
                            duration-300 
                            ease-in-out 
                            w-full 
                            px-2 
                            py-4 
                          text-white 
                            font-semibold 
                          bg-[#FF5EA3] 
                            rounded-lg 
                          hover:bg-[#FF7DF2] 
                            hover:scale-105 p-3"
                      onClick={handleAgregarClick}
                    >
                      AGREGAR
                    </button>
                  </div>
                </div>

              </div>
              {errorMessage && (
                <p className="text-red-500 mt-2 text-center">{errorMessage}</p>
              )}
              {successMessage && (
                <p className="text-green-500 mt-2 text-center">{successMessage}</p>
              )}
            </div>
          ) : (
            "No hay datos disponibles"
          )}
        </Modal>
      </main>
    </div>
  );
}
