
// Esta función abre la base de datos IndexedDB (le puse 'PropinasDB' LOoooooool). Si es la primera vez que se abre, crea un almacén de objetos llamado 'monthlyTips'
// Porque en ingles? Porque asi nos acostumbramos a laburar para afuera, gente... dejen de usar castellano o despues les va a costar mas!
// Le metemos una clave primaria 'month'. Devuelve una promesa que se resuelve con la instancia de la base de datos si todo va bien, o se pone en puto si aparece un error.
export const openDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('PropinasDB', 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('monthlyTips')) {
                db.createObjectStore('monthlyTips', { keyPath: 'month' });
            }
        };

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
};


// Este cachito agarra la info de la base de datos usando una clave específica en 'monthlyTips'. Devuelve una promesa que se resuelve con
// un objeto que tiene el total, el conteo y la calificación promedio (tres en uno!), o con null si no hay datos para esa clave. Si hay algún error, el codigo
// nos rompe el corazon, y la promesa, falla ;-(

export const getFromDB = (db, key) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['monthlyTips'], 'readonly');
        const store = transaction.objectStore('monthlyTips');
        const request = store.get(key);

        request.onsuccess = () => {
            const result = request.result;
            resolve(result ? { total: result.total, count: result.count, averageRating: result.averageRating } : null);
        };
        request.onerror = () => reject(request.error);
    });
};


// Aca guardamos o actualizamos los datos en 'monthlyTips' (el total, el conteo de propinas y calificación promedio). 
// Devolvemos una promesa que se resuelve cuando la operación sale con fritas, o se rechaza como me rechazo la toxica si hay un error.
export const saveToDB = (db, key, value) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['monthlyTips'], 'readwrite');
        const store = transaction.objectStore('monthlyTips');
        const request = store.put({ 
            month: key, 
            total: value.total, 
            count: value.count, 
            averageRating: value.averageRating // Guardar el promedio del rating que dejo el usuario al mozo (zero no debiera de existir, pero bueh...)
        });

        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
    });
};
