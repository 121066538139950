import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ArrowRight } from "react-bootstrap-icons";

const TurnosCalendar = () => {
  const [turnos, setTurnos] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);

  const tileContent = ({ date, view }) => {
    if (view === "month" && getTurnosForDate(date).length > 0) {
      return (
        <div className="w-1.5 h-1.5 absolute ml-3 rounded-full mx-auto mt-[-2px] dotActive" />
      );
    }
    return null;
  };

  useEffect(() => {
    const fetchTurnos = async () => {
      setLoading(true);
      const token = sessionStorage.getItem("token");
      if (!token) {
        throw new Error("No token found in session storage");
      }

      try {
        const response = await fetch(
          "https://tippitqr.com/userdb/turnos_activos",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching turnos");
        }

        const data = await response.json();
        setTurnos(data.filter((turno) => !turno.activo));
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTurnos();
  }, []);

  const getTurnosForDate = (date) => {
    const filteredTurnos = turnos.filter((turno) => {
      const turnoDate = new Date(turno.hora_inicio);
      return turnoDate.toDateString() === date.toDateString();
    });
    // console.log('Filtered Turnos:', filteredTurnos); // Add this line to debug
    return filteredTurnos;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="w-full">
      <hr className="mb-5 mt-7 mx-[-30px]" />
      <h1 className="text-2xl font-semibold mb-2">Turnos Pasados</h1>
      <p className="text-md md:text-lg mb-7">
        Para ver el historial de turnos pasados, seleccione una fecha en el
        calendario. Los días con turnos registrados estarán resaltados. Al hacer
        clic en una fecha destacada, podrá ver los detalles de los turnos
        correspondientes a esa fecha.
      </p>
      {loading ? (
        <p>Cargando Turnos...</p>
      ) : (
        <div className="flex flex-col lg:flex-row">
          <div className="w-full mr-7 rounded-2xl order-2 lg:order-1 mt-4 lg:mt-0 border-4 border-gray-200 bg-gray-100">
            {getTurnosForDate(selectedDate).length > 0 ? (
              getTurnosForDate(selectedDate).map((turno) => (
                <div key={turno._id} className="bg-white mx-2 mt-3 mb-7 border rounded-xl shadow-lg transition-all ease-in-out hover:shadow-xl hover:shadow-gray-400 grayscale hover:grayscale-0">
                  <div className="bg-purple-200 rounded-t-md w-full p-5">
                    <h2 className="text-xl font-bold hidden">
                      Turno ID: {turno._id}
                    </h2>
                    <p className="text-xl mb-1">
                      <strong>Hora Inicio:</strong>{" "}
                      {new Date(turno.hora_inicio).toLocaleString()}
                    </p>
                    <p className="text-xl">
                      <strong>Hora Fin:</strong> {turno.hora_fin}
                    </p>
                    <p className="text-xl">
                      <strong>Monto Efectivo:</strong> ${turno.monto_efectivo}
                    </p>
                  </div>
                  {turno.pagos.length > 0 && (
                    <div className="p-4">
                      <h3 className="text-lg font-semibold mt-2 bg-gray-100 px-2 py-1 rounded-md mb-3">
                        Pagos:
                      </h3>
                      <ul>
                        {turno.pagos.map((pago) => (
                          <li
                            key={`${turno._id}-${pago.id}-${pago.mozo_cuit}`}
                            className="mb-3 border shadow-md p-3 rounded-md text-lg font-mono"
                          >
                            <p>
                              <strong>Mozo:</strong> {pago.mozo_nombre}
                            </p>
                            <p>
                              <strong>CUIT:</strong> {pago.mozo_cuit}
                            </p>
                            <p>
                              <strong>Monto:</strong> ${pago.monto}
                            </p>
                            <p>
                              <strong>Detalles de Comisión:</strong>
                            </p>
                            <ul>
                              {pago.fee_details.map((fee, index) => {
                                const feeTypeMap = {
                                  mercadopago: "Mercadopago",
                                  application: "Tippit",
                                };

                                const feeName =
                                  feeTypeMap[fee.type.replace("_fee", "")] ||
                                  fee.type.replace("_fee", "");

                                return (
                                  <li key={`${turno._id}-${pago.id}-${index}-${fee.type}`}>
                                    {feeName}: ${fee.amount}
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {turno.transferencias.length > 0 && (
                    <div className='px-3 pb-5'>
                      <h3 className="text-lg font-semibold mt-2 bg-gray-100 px-2 py-1 rounded-md mb-3">Transferencias:</h3>
                      <div className="mb-3 border shadow-md p-3 rounded-md text-lg bg-blue-100 border-blue-300">
                        <ul>
                          {turno.transferencias.map((transferencia, index) => (
                            <li key={`${turno._id}-transferencia-${index}`} className="my-1 mx-1">
                              {transferencia}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-gray-500 text-center text-2xl mr-2">
                  Por favor, elegí una fecha
                </p>{" "}
                <ArrowRight className="text-2xl" />
              </div>
            )}
          </div>
          <div className="lg:w-1/3 order-1 lg:order-2">
            <Calendar
              className="text-xl shadow-lg p-3 rounded-lg text-gray-600 border-none"
              onChange={handleDateChange}
              value={selectedDate}
              locale="es"
              tileContent={tileContent}
              tileClassName={({ date, view }) => {
                if (view === "month") {
                  if (date.toDateString() === selectedDate.toDateString()) {
                    return "bg-blue-500 text-white rounded-lg";
                  }
                  if (getTurnosForDate(date).length > 0) {
                    return "bg-blue-200";
                  }
                }
                return "";
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TurnosCalendar;
