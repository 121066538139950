import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RestauranteBotones from "../components/RestauranteBotones.js";
import { fetchUserToken } from "../utils/AuthToken.js";
import ActivarResto from "../components/ActivarResto.js";
import Mesa from "../components/Mesa.js";
import Mozo from "../components/Mozo.js";
import Payments from "../components/Payments.js";
import Tiempo from "../components/Tiempo.js";
import useDateFormatter from "../utils/useDateFormatter.js";
//import { getMesaData, getMozoData } from "../utils/restaurantDataGetter.js"
import {
  Coin,
  Eye,
  EyeSlash,
  PersonCircle,
} from "react-bootstrap-icons";

function RestauranteDashboard({ token }) {
  const [isVisible, setIsVisible] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const [weeklyData, setWeeklyData] = useState([]);
  const [groupedPayments, setGroupedPayments] = useState({});
  const [totalPayments, setTotalPayments] = useState(0);
  const [isAmountVisible, setIsAmountVisible] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [activeSubButton, setActiveSubButton] = useState(null);
  const [mesaDetails, setMesaDetails] = useState({});
  const [mozoDetails, setMozoDetails] = useState({});
  const [expandedGroups, setExpandedGroups] = useState({});
  const [cuentaActiva, setCuentaActiva] = useState(false);
  const [cuitRestaurante, setCuitRestaurante] = useState("");
  //const { obtenerDia } = useDateFormatter()



  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const getUserToken = async () => {
      setTimeout(async () => {
        try {
          const data = await fetchUserToken();
          setUserToken(data);
          const isCuentaActiva = data.activada;
          setCuitRestaurante(data.cuit);
          setCuentaActiva(isCuentaActiva);
          console.log("CUENTA ACTIVADA:", isCuentaActiva);
          if (isCuentaActiva === false) {
            setIsModalOpen(true);
          }
        } catch (error) {
          console.error("Error fetching user token:", error);
        }
      }, 1000);
    };

    window.addEventListener("load", getUserToken);
    getUserToken();

    return () => {
      window.removeEventListener("load", getUserToken);
    };

  }, []);

  // esta jodiendo, despues lo arreglo
  //   console.log(userToken);
  //   console.log("Estado de cuentaActiva:", cuentaActiva);

  const checkVisibility = () => {
    const elements = document.getElementsByClassName("flip-container");
    Array.from(elements).forEach((element) => {
      const rect = element.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth);

      if (isVisible) {
        element.classList.add("hover");
      } else {
        element.classList.remove("hover");
      }
    });
  };

  const filterData = () => {
    let filtered = [];
    const now = new Date();

    if (activeButton === "Tiempo") {
      if (activeSubButton === "UltimaSemana") {
        const weekAgo = new Date();
        weekAgo.setDate(now.getDate() - 7);
        filtered = weeklyData.filter(
          (payment) =>
            new Date(payment.propina.hora_aprovacion_pago) >= weekAgo
        );
      } else if (activeSubButton === "UltimaMes") {
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        filtered = weeklyData.filter(
          (payment) =>
            new Date(payment.propina.hora_aprovacion_pago) >= startOfMonth
        );
      } else if (activeSubButton === "UltimoAnio") {
        const yearStart = new Date(now.getFullYear(), 0, 1);
        filtered = weeklyData.filter(
          (payment) =>
            new Date(payment.propina.hora_aprovacion_pago) >= yearStart
        );
      } else {
        filtered = weeklyData;
      }
    } else {
      filtered = weeklyData;
    }

    return filtered;
  };

  useEffect(() => {
    const handleScroll = () => {
      checkVisibility();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    checkVisibility();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    setActiveSubButton(null);
  };

  const handleSubButtonClick = (button) => {
    setActiveSubButton(button);
  };


  // const formatDate = obtenerDia(dateString);


  const formatDate = (dateString) => {
    const paymentDate = new Date(dateString);
    const currentDate = new Date();
    const paymentDay = paymentDate.getDate();
    const paymentMonth = paymentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    if (paymentDay === currentDay && paymentMonth === currentMonth) {
      return "Hoy";
    } else if (paymentDay === currentDay - 1 && paymentMonth === currentMonth) {
      return "Ayer";
    } else {
      return paymentDate.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
      });
    }
  };

  useEffect(() => {
    if (!token) {
      console.error("No se encontró el token");
      navigate("/");
      return;
    }

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 600);

    return () => clearTimeout(timeout);
  }, [token, navigate]);

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        const fetchWeeklyData = (await import("../components/YearlyData.js"))
          .default;
        const data = await fetchWeeklyData(token, navigate);
        if (data && data.propinas_año_actual) {
          if (process.env.NODE_ENV === "development") {
            // console.log(data.propinas_año_actual);
          }
          setWeeklyData(data.propinas_año_actual);
        }
      };
      fetchData();
    }
  }, [token, navigate]);

  //TODO: refactorizar este useEffect
  useEffect(() => {
    if (weeklyData.length > 0) {
      const filterData = () => {
        let filtered = [];
        const now = new Date();

        if (activeButton === "Tiempo") {
          if (activeSubButton === "UltimaSemana") {
            const weekAgo = new Date();
            weekAgo.setDate(now.getDate() - 7);
            filtered = weeklyData.filter(
              (payment) =>
                new Date(payment.propina.hora_aprovacion_pago) >= weekAgo
            );
          } else if (activeSubButton === "UltimaMes") {
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            filtered = weeklyData.filter(
              (payment) =>
                new Date(payment.propina.hora_aprovacion_pago) >= startOfMonth
            );
          } else if (activeSubButton === "UltimoAnio") {
            const yearStart = new Date(now.getFullYear(), 0, 1);
            filtered = weeklyData.filter(
              (payment) =>
                new Date(payment.propina.hora_aprovacion_pago) >= yearStart
            );
          } else {
            filtered = weeklyData;
          }
        } else {
          filtered = weeklyData;
        }

        return filtered;
      };

      const filteredData = filterData();

      let groupedData = {};
      let mesaDetails = {};
      let mozoDetails = {};

      if (activeButton === "Mesa") {
        groupedData = filteredData.reduce((acc, data) => {
          const mesa = data.propina.mesa;
          if (!acc[mesa]) {
            acc[mesa] = [];
          }
          acc[mesa].push(data.propina);
          return acc;
        }, {});

        Object.keys(groupedData).forEach((mesa) => {
          const payments = groupedData[mesa];
          const mozoCount = {};
          let highestMonto = 0;
          let totalCalificacion = 0;
          let totalMonto = 0;

          payments.forEach((payment) => {
            const mozo = payment.Mozo.nombre;
            if (!mozoCount[mozo]) {
              mozoCount[mozo] = 0;
            }
            mozoCount[mozo] += 1;

            highestMonto = Math.max(highestMonto, parseFloat(payment.monto));
            totalCalificacion += parseFloat(payment.calificacion);
            totalMonto += parseFloat(payment.monto);
          });

          const averageMonto = totalMonto / payments.length;
          const averageCalificacion = totalCalificacion / payments.length;
          const mostFrequentMozo = Object.keys(mozoCount).reduce((a, b) =>
            mozoCount[a] > mozoCount[b] ? a : b
          );

          mesaDetails[mesa] = {
            mostFrequentMozo,
            highestMonto,
            averageCalificacion,
            averageMonto,
            photo: payments[0].Mozo.photo || "profile_she.jpg",
          };
        });
      } else if (activeButton === "Mozo") {
        groupedData = filteredData.reduce((acc, data) => {
          const mozoName = `${data.propina.Mozo.nombre} ${data.propina.Mozo.apellido}`;
          if (!acc[mozoName]) {
            acc[mozoName] = [];
          }
          acc[mozoName].push(data.propina);
          return acc;
        }, {});

        Object.keys(groupedData).forEach((mozoName) => {
          const payments = groupedData[mozoName];
          const mozoCount = {};
          let highestMonto = 0;
          let totalCalificacion = 0;
          let totalMonto = 0;

          payments.forEach((payment) => {
            const mozo = payment.Mozo.nombre;
            if (!mozoCount[mozo]) {
              mozoCount[mozo] = 0;
            }
            mozoCount[mozo] += 1;

            highestMonto = Math.max(highestMonto, parseFloat(payment.monto));
            totalCalificacion += parseFloat(payment.calificacion);
            totalMonto += parseFloat(payment.monto);
          });

          const averageMonto = totalMonto / payments.length;
          const averageCalificacion = totalCalificacion / payments.length;
          const mostFrequentMozo = Object.keys(mozoCount).reduce((a, b) =>
            mozoCount[a] > mozoCount[b] ? a : b
          );

          mozoDetails[mozoName] = {
            mostFrequentMozo,
            highestMonto,
            averageCalificacion,
            averageMonto,
            photo: payments[0].Mozo.photo || "profile_she.jpg",
            totalMonto
          };
        });
      } else {
        groupedData = filteredData.reduce((acc, data) => {
          const dateObj = new Date(data.propina.hora_aprovacion_pago);
          let formattedDate;

          if (activeSubButton === "UltimoAnio") {
            formattedDate = dateObj.toLocaleDateString("es-ES", {
              month: "long",
              year: "numeric",
            });
          } else {
            formattedDate = formatDate(dateObj);
          }

          if (!acc[formattedDate]) {
            acc[formattedDate] = { date: dateObj, payments: [] };
          }
          acc[formattedDate].payments.push(data.propina);
          return acc;
        }, {});
      }

      if (activeButton !== "Mesa" && activeButton !== "Mozo") {
        const groupedArray = Object.keys(groupedData).map((key) => ({
          date: groupedData[key].date,
          formattedDate: key,
          payments: groupedData[key].payments,
        }));

        groupedArray.sort((a, b) => b.date - a.date);

        const orderedGroupedData = {};
        groupedArray.forEach((group) => {
          orderedGroupedData[group.formattedDate] = group.payments;
        });

        setGroupedPayments(orderedGroupedData);
      } else {
        setGroupedPayments(groupedData);
        setMesaDetails(mesaDetails);
        setMozoDetails(mozoDetails);
      }

      const total = filteredData.reduce(
        (sum, payment) => sum + parseFloat(payment.propina.monto),
        0
      );
      setTotalPayments(
        total.toLocaleString("en-US", { maximumFractionDigits: 2 })
      );
    }
  }, [weeklyData, activeButton, activeSubButton]);


  const getPeriodText = () => {
    if (activeSubButton === "UltimaSemana") {
      return "(esta semana)";
    } else if (activeSubButton === "UltimaMes") {
      return "(ultimos 30 dias)";
    } else if (activeSubButton === "UltimoAnio") {
      return "(este año)";
    } else {
      return "(este año)";
    }
  };

  const groupPaymentsByMozo = (payments) => {
    
    const dataArray = Array.from(Object.entries(payments), ([key, value]) => ({
      name: key,
      ...value
    }));
    console.log(dataArray)
    return dataArray
  };


  const groupedPaymentsArray = groupPaymentsByMozo(mozoDetails);


  // Función para manejar el clic en "Mostrar Todos" de un grupo específico
  const toggleExpand = (key) => {
    setExpandedGroups((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key], // Invertir el estado de expandir o contraer
    }));
  };

  return (
    <div>
      <h1 className="hidden md:block text-xl bg-pink-500 text-white p-2 font-sans mt-0 pt-1 pl-10 mb-4 ">
        Su Restaurante
      </h1>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: isVisible ? 1000 : -1000,
          backgroundColor: "#FF5EA3",
          opacity: isVisible ? 1 : 0,
          transition: "opacity .3s, z-index 0s 0.3s",
        }}
      />
      <div className="w-full min-h-screen pt-3 pb-10 md:bg-none bg-mobile-gradient">
        <div className="flex items-left justify-left text-white w-11/12 mx-auto mb-2 mt-3 text-sm md:hidden">
          <PersonCircle size={22} color="#fff" className="float-left mr-2" />
          <h2>Hola {sessionStorage.getItem("nombre")}!</h2>
        </div>

        <div className="flex flex-col md:flex-row gap-4 md:w-11/12 mx-auto items-center justify-center max-w-[900px]">
          <div
            id="div1"
            className={`rounded-md w-11/12 md:w-11/12 mx-auto p-4 py-5 md:py-6 md:my-5 md:h-[120px] cursor-pointer transition-all ${isAmountVisible
                ? "shadow-lg shadow-pink-700 bg-white md:shadow-gray-300"
                : "bg-pink-900 text-white"
              }`}
            onClick={() => setIsAmountVisible(!isAmountVisible)}
          >
            <div className="text-3xl font-semibold font-mono flex items-center justify-center">
              {isAmountVisible ? `$${totalPayments}` : "$****"}
              {isAmountVisible ? (
                <Eye size={30} color="#333" className="ml-2" />
              ) : (
                <EyeSlash size={30} color="#fff" className="ml-2" />
              )}
            </div>
            <div className="flex items-center justify-center text-sm mt-2">
              {isAmountVisible ? (
                <Coin size={20} color="#333" className="ml-2" />
              ) : (
                <Coin size={20} color="#fff" className="ml-2" />
              )}
              &nbsp; Total de propinas {getPeriodText()}
            </div>
          </div>

          <div
            id="div2"
            className="text-center bg-white w-11/12 mx-auto rounded-lg py-3 md:p-0 md:h-[120px] my-5 shadow-lg shadow-gray-250 flex items-center justify-center"
          >
            <RestauranteBotones
              activeButton={activeButton}
              handleButtonClick={handleButtonClick}
            />
          </div>
        </div>

        <div
          className="w-11/12 pt-5 mt-2 px-5 pb-5 min-h-full mx-auto bg-white shadow-lg shadow-gray-250 rounded-lg max-w-[900px]"
          id="WeeklyReporte"
        >
          {activeButton === "Tiempo" && (
            <Tiempo
              activeSubButton={activeSubButton}
              handleSubButtonClick={handleSubButtonClick}
            />
          )}

          {Object.entries(groupedPayments).map(([key, payments], index) => (
            <div key={key}>
              {activeButton === "Mesa" && (
                <Mesa pos={key} mesaDetails={mesaDetails} />
              )}

              {activeButton === "Mozo" && (
                <Mozo
                  pos={key}
                  mozoDetails={mozoDetails}
                  groupedPaymentsArray={groupedPaymentsArray}
                />
              )}

              {activeButton !== "Mesa" && (
                <h3 className="bg-gray-100 rounded-md p-2 text-sm mt-2">
                  {key}
                </h3>
              )}
              {payments
                .sort(
                  (a, b) =>
                    new Date(b.hora_aprovacion_pago) -
                    new Date(a.hora_aprovacion_pago)
                )
                .slice(0, expandedGroups[key] ? payments.length : 5)
                .map((payment, paymentIndex) => (
                  <Payments
                    key={paymentIndex}
                    paymentIndex={paymentIndex}
                    activeButton={activeButton}
                    activeSubButton={activeSubButton}
                    mesaDetails={mesaDetails}
                    payment={payment}
                    cantPayments={payments.length}
                    pos={key}
                  />
                ))}
              {!expandedGroups[key] && payments.length > 5 && (
                <div className="text-center">
                  <button
                    className="
                    transition:all 
                    duration-300 
                    ease-in-out 
                    mt-5 
                    mb-12
                    text-sm 
                    md:text-lg 
                    text-white 
                    py-3 
                    px-5 
                    w-full
                    md:w-1/4
                     
                  bg-[#FF5EA3] 
                    rounded-md 
                    hover:bg-[#FF7DF2] 
                    hover:scale-105 "
                    onClick={() => toggleExpand(key)}
                  >
                    Mostrar Todos
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <ActivarResto
        isModalOpen={isModalOpen}
        openModal={openModal}
        closeModal={closeModal}
        cuitRestaurante={cuitRestaurante}
      />
    </div>
  );

}

export default RestauranteDashboard;
