let isFetching = false; // Flag to prevent multiple fetches

const getToken = () => sessionStorage.getItem("token");

const openTippitDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("TippitDB", 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("restaurante")) {
                db.createObjectStore("restaurante");
            }
        };

        request.onsuccess = (event) => resolve(event.target.result);
        request.onerror = (event) => reject(`Error opening database: ${event.target.errorCode}`);
    });
};

const deleteDatabase = (dbName) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(dbName);

        request.onsuccess = () => resolve(`Base de datos ${dbName} eliminada con éxito.`);
        request.onerror = (event) => reject(`Error al eliminar la base de datos ${dbName}: ${event.target.errorCode}`);
    });
};

const getRestaurantName = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openTippitDatabase();
            const transaction = db.transaction(["restaurante"], "readonly");
            const store = transaction.objectStore("restaurante");
            const request = store.get("Nombre Restaurante");

            request.onsuccess = (event) => resolve(event.target.result);
            request.onerror = () => reject("Error al obtener el nombre del restaurante.");
        } catch (error) {
            reject("Error en IndexDB");
            if (process.env.NODE_ENV === "development") {
                console.error("Error obteniendo nombre de restaurante de TippitDB:", error);
            } else {
                console.error("Error al acceder a la base de datos.");
            }
        }
    });
};

const saveRestaurantName = async (name) => {
    try {
        const db = await openTippitDatabase();
        const transaction = db.transaction(["restaurante"], "readwrite");
        const store = transaction.objectStore("restaurante");
        const request = store.put(name, "Nombre Restaurante");

        return new Promise((resolve, reject) => {
            request.onsuccess = () => resolve("Nombre del restaurante guardado con éxito.");
            request.onerror = (event) => reject(`Error al guardar el nombre del restaurante: ${event.target.errorCode}`);
        });
    } catch (error) {
        console.error("Error en IndexDB");
        if (process.env.NODE_ENV === "development") {
            console.error("Error guardando nombre de restaurante en TippitDB:", error);
        } else {
            console.error("Error al guardar datos en la base de datos.");
        }
    }
};

const checkAndSaveRestaurantName = async (fetchedName, dbNames) => {
    try {
        // Comprueba y actualiza el nombre en TippitDB
        const currentName = await getRestaurantName();
        if (process.env.NODE_ENV === "development") {
            console.log("Nombre actual del restaurante:", currentName);
            console.log("Nombre del restaurante Fetched:", fetchedName);
        }
        if (currentName !== fetchedName) {
            if (process.env.NODE_ENV === "development") {
                console.log("Nuevo restaurante detectado, purgando IndexDB");
            }
            for (const dbName of dbNames) {
                if (dbName !== "TippitDB") {
                    await deleteDatabase(dbName);
                }
            }
            await saveRestaurantName(fetchedName);
            if (process.env.NODE_ENV === "development") {
                console.log("IndexDB purgada, nuevo restaurante guardado.");
            }
        }
    } catch (error) {
        console.error("Error en checkAndSaveRestaurantName:", error);
    }
};

// Fetch el token de usuario y maneja el almacenamiento del nombre
export const fetchUserToken = async () => {
    if (isFetching) return; // Avoid multiple fetches
    isFetching = true;

    try {
        const response = await fetch("https://tippitqr.com/userdb/token", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${getToken()}`,
            },
        });

        if (!response.ok) throw new Error(`Error HTTP! status: ${response.status}`);

        const data = await response.json();

        if (process.env.NODE_ENV === 'development') {
            console.log("Token de usuario AuthToken:", data);
        } else {
            console.log("Token de usuario recibido con éxito.");
        }

        const dbNames = ["MiPersonal", "PropinasDB", "TippitDB"];

        if (data?.nombre) {
            await checkAndSaveRestaurantName(data.nombre, dbNames);
        }

        return data;
    } catch (error) {
        console.error("Error al obtener el token de usuario:", error);
        throw error;
    } finally {
        isFetching = false;
    }
};
