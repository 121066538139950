import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import Modal from "../utils/Modal";
import { XCircleFill, Copy } from "react-bootstrap-icons";

const GiftCards = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [sessionOut, setSessionOut] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    beneficio: '',
    fecha_vencimiento: '',
    codigo: '',
  });

  const [copiedEmailId, setCopiedEmailId] = useState(null);
  const [copiedCodeId, setCopiedCodeId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [validatedGiftCard, setValidatedGiftCard] = useState(null);
  const [validationStep, setValidationStep] = useState('enter_code'); 

  useEffect(() => {
    let currentToken = location.state?.token;
    if (!currentToken) {
      currentToken = sessionStorage.getItem("token");
    } else {
      sessionStorage.setItem("token", currentToken);
    }

    if (!currentToken) {
      navigate("/");
    } else {
      setToken(currentToken);
    }
  }, [navigate, location.state]);

  useEffect(() => {
    if (token) {
      fetchGiftCards();
    }
  }, [token]);

  const fetchGiftCards = async () => {
    try {
      const response = await fetch('https://tippitqr.com/GIFT/', {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setGiftCards(data);
      } else {
        setSessionExpired(true);
      }
    } catch (error) {
      console.error('Error al obtener las gift cards:', error);
    }
  };

  const handleCreate = () => {
    setModalType('create');
    setIsModalOpen(true);
  };

  const handleValidate = () => {
    setModalType('validate');
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setFormData({
      nombre: '',
      email: '',
      beneficio: '',
      fecha_vencimiento: '',
      codigo: '',
    });
    setIsSubmitting(false);
    
    setValidationStep('enter_code');
    setValidatedGiftCard(null);
  };

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const fechaFormateada = formatDateToDDMMYYYY(formData.fecha_vencimiento);

    try {
      const response = await fetch('https://tippitqr.com/GIFT/crear', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${token}`,
        },
        body: new URLSearchParams({
          nombre: formData.nombre,
          fecha_vencimiento: fechaFormateada,
          email: formData.email,
          beneficio: formData.beneficio,
        }),
      });
      if (response.ok) {
        fetchGiftCards();
        handleModalClose();
      } else {
        const errorData = await response.json();
        console.error('Error al crear la gift card:', errorData);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error al crear la gift card:', error);
      setIsSubmitting(false);
    }
  };

  const handleValidateSubmit = (e) => {
    e.preventDefault();
    const giftCard = giftCards.find(card => card.code_used === formData.codigo);
    if (giftCard) {
      setValidatedGiftCard(giftCard);
      setValidationStep('confirm');
    } else {
      console.error('Gift card not found');
      alert('Código no válido. Por favor, intente nuevamente.');
    }
  };

  const handleMarkAsUsed = async () => {
    try {
      const response = await fetch('https://tippitqr.com/GIFT/validar_gift', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${token}`,
        },
        body: new URLSearchParams({ codigo: formData.codigo }),
      });
      if (response.ok) {
        fetchGiftCards();
        handleModalClose();
      } else {
        console.error('Error al marcar la gift card como usada:', response.statusText);
      }
    } catch (error) {
      console.error('Error al marcar la gift card como usada:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const formatDateToDDMMYYYY = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div id="modal-root"></div>
      <div className="flex flex-col h-screen">
        <div className="relative block bg-gray-100">
          <div className="flex flex-1 overflow-auto">
            <Menu rol="Restaurante" token={token} />
            <div className="flex-1 w-full">
              <h1 className="block md:hidden text-xl bg-pink-500 text-white p-2 font-sans mt-0 pt-1 pl-10 mb-4">
                Mis Gift Cards
              </h1>
              <div className="w-11/12 max-w-[900px] pt-8 lg:mt-10 px-8 pb-3 mx-auto bg-white overflow-hidden shadow-lg shadow-gray-250 rounded-full relative mb-10">
                <div className="flex justify-between items-center mb-4">
                  <h1 className="text-2xl font-semibold hidden lg:block">
                    Mis Gift Cards
                  </h1>
                  <div className="flex items-center space-x-2">
                    <div className="relative">
                      <input
                        type="text"
                        className="pl-10 pr-2 py-1 border rounded-full"
                        placeholder="Buscar..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg
                          className="w-5 h-5 text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.9 14.32a8 8 0 111.414-1.414l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387zM8 14a6 6 0 100-12 6 6 0 000 12z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="flex">
                      <button
                        className="px-4 py-1 bg-pink-500 text-white rounded-l-full border border-pink-500 font-semibold text-sm"
                        onClick={handleCreate}
                      >
                        CREAR
                      </button>
                      <button
                        className="px-4 py-1 bg-pink-200 text-pink-500 border-t border-b border-pink-500 font-semibold text-sm"
                      >
                        BUSCAR
                      </button>
                      <button
                        className="px-4 py-1 bg-pink-200 text-pink-500 rounded-r-full border border-pink-500 font-semibold text-sm"
                        onClick={handleValidate}
                      >
                        VALIDAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-11/12 max-w-[900px] lg:mt-10 px-2 pb-10 min-h-96 mx-auto overflow-hidden relative mb-10">
                <div className="grid gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                  <div className="border border-pink-500 rounded-lg p-4 text-center bg-white">
                    <img src="./gift.png" className="w-40 mx-auto mt-2" />
                    <h2 className="text-lg font-semibold">CREAR GIFT NUEVA</h2>
                    <p className="text-gray-400">Crear una gift card nueva</p>
                    <button
                      className="mt-4 bg-pink-500 text-white px-4 py-2 rounded-full hover:bg-pink-700"
                      onClick={handleCreate}
                    >
                      Agregar
                    </button>
                  </div>
                  {[...giftCards].reverse().map((card) => (
                    <div
                      key={card.code_used}
                      className={`border border-transparent hover:shadow-lg transition-all rounded-lg p-4 text-center text-sm bg-white hover:border ${card.used ? 'opacity-60 hover:shadow-none' : ''}`}
                    >
                      <h2 className="text-xl font-semibold">{card.name}</h2>

                      <div className="text-sm mb-2 mt-2 relative">
                        <label>
                          Email:
                          {copiedEmailId === card.code_used && (
                            <span className="text-xs text-red-700 font-semibold mt-0.5 absolute right-0">COPIADO!</span>
                          )}
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            value={card.email}
                            readOnly
                            onClick={(e) => {
                              e.target.select();
                              navigator.clipboard.writeText(card.email);
                              setCopiedEmailId(card.code_used);
                              setTimeout(() => setCopiedEmailId(null), 2000);
                            }}
                            className="cursor-pointer border focus:outline-none py-1 px-2 pr-7 rounded bg-gray-100 hover:border-gray-400 w-full"
                          />
                          <span className="absolute inset-y-0 right-2 flex items-center">
                            <Copy
                              className="cursor-pointer text-gray-500 bg-gray-100 hover:text-gray-700"
                              onClick={() => {
                                navigator.clipboard.writeText(card.email);
                                setCopiedEmailId(card.code_used);
                                setTimeout(() => setCopiedEmailId(null), 2000);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="text-sm mb-2 mt-2 relative">
                        <label>
                          Código:
                          {copiedCodeId === card.code_used && (
                            <span className="text-xs text-red-700 font-semibold mt-0.5 absolute right-0">COPIADO!</span>
                          )}
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            value={card.code_used}
                            readOnly
                            onClick={(e) => {
                              e.target.select();
                              navigator.clipboard.writeText(card.code_used);
                              setCopiedCodeId(card.code_used);
                              setTimeout(() => setCopiedCodeId(null), 2000);
                            }}
                            className="cursor-pointer border focus:outline-none py-1 pl-2 pr-7 rounded bg-gray-100 hover:border-gray-400 w-full"
                          />
                          <span className="absolute inset-y-0 right-2 flex items-center">
                            <Copy
                              className="cursor-pointer text-gray-500 hover:text-gray-700"
                              onClick={() => {
                                navigator.clipboard.writeText(card.code_used);
                                setCopiedCodeId(card.code_used);
                                setTimeout(() => setCopiedCodeId(null), 2000);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      <p className="mt-2">Beneficio:</p>
                      <p className="rounded p-2 font-semibold border-dashed border-2 border-gray-400 bg-yellow-50">{card.benefit}</p>
                      <p className="mt-2 mb-5">Expira: {formatDate(card.expiration_date)}</p>
                      {card.used && (
                        <p className="bg-red-100 border-red-700 border inline py-1 px-2 rounded-full text-red-700">
                          Usada
                        </p>
                      )}
                    </div>
                  ))}
                </div>

                <div>
                  {sessionExpired && <SessionOut />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        {modalType === 'create' && (
          <form onSubmit={handleCreateSubmit} className="p-4 text-sm">
            <h2 className="text-xl font-semibold mb-4">Crear Gift Card</h2>
            <div className="mb-2">
              <input
                type="text"
                name="nombre"
                placeholder="Nombre y Apellido"
                value={formData.nombre}
                onChange={handleFormChange}
                className="w-full border px-3 py-1.5 rounded mb-1"
                required
              />
            </div>
            <div className="mb-2">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                className="w-full border px-3 py-1.5 rounded mb-1"
                required
              />
            </div>
            <div className="mb-2">
              <input
                type="text"
                name="beneficio"
                placeholder="Beneficio"
                value={formData.beneficio}
                onChange={handleFormChange}
                className="w-full border px-3 py-1.5 rounded mb-1"
                required
              />
            </div>
            <div className="mb-2">
              <label className="block font-semibold text-sm">Vencimiento de la Gift Card</label>
              <input
                type="date"
                name="fecha_vencimiento"
                value={formData.fecha_vencimiento}
                onChange={handleFormChange}
                className="w-full border px-3 py-1.5 rounded"
                required
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="mt-4 bg-pink-500 text-white px-4 py-2 rounded w-full text-lg hover:bg-pink-600"
                disabled={isSubmitting}
              >
                Agregar Gift Card
              </button>
            </div>
          </form>
        )}
        {modalType === 'validate' && (
          <>
            {validationStep === 'enter_code' && (
              <form onSubmit={handleValidateSubmit} className="p-4">
                <h2 className="text-xl font-semibold mb-4">Validar Gift Card</h2>
                <div className="mb-2">
                  <label className="block mb-1">Código</label>
                  <input
                    type="text"
                    name="codigo"
                    value={formData.codigo}
                    onChange={handleFormChange}
                    className="w-full border px-2 py-1 rounded"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="mt-4 bg-pink-500 text-white px-4 py-2 rounded w-full text-lg hover:bg-pink-600"
                >
                  Validar Gift Card
                </button>
              </form>
            )}
            {validationStep === 'confirm' && (
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-4">Validar Gift Card</h2>
                <p><strong>Beneficio:</strong> {validatedGiftCard.benefit}</p>
                <p><strong>Fecha de Expiración:</strong> {formatDate(validatedGiftCard.expiration_date)}</p>
                <button
                  onClick={handleMarkAsUsed}
                  className="mt-4 bg-pink-500 text-white px-4 py-2 rounded w-full text-lg hover:bg-pink-600"
                >
                  Marcar Como Usada
                </button>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default GiftCards;