import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import CalendarTurnos from "../components/CalendarTurnos";
import Switch from "../components/FormSwitch";
import ToolTip from "../utils/ToolTip.js";

const Turno = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [status, setStatus] = useState(null);
  const [mozos, setMozos] = useState([]);
  const [selectedMozos, setSelectedMozos] = useState([]);
  const [montoEfectivo, setMontoEfectivo] = useState("");
  const [finalizarResponse, setFinalizarResponse] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [turnoTiempo, setTurnoTiempo] = useState("");
  const [backup, setBackup] = useState(false);
  const [switchCount, setSwitchCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [nameFilter, setNameFilter] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [roles, setRoles] = useState([]); 
  const [efectivoEnabled, setEfectivoEnabled] = useState(false); 
  const [efectivoValues, setEfectivoValues] = useState({}); 
  const [selectAllChecked, setSelectAllChecked] = useState(false); 

  const currentToken = location.state?.token;

  const checkTurnoActivo = useCallback(async () => {
    try {
      if (!currentToken) {
        return;
      }

      const response = await fetch("https://tippitqr.com/userdb/hay_activo", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const data = await response.json();

      setStatus(data.status);

      if (response.status === 200) {
        setShowCreateForm(true); 
      } else if (response.status === 401) {
        setSessionExpired(true);
      } else if (response.status === 400) {
        setShowCreateForm(false); 
      } else {
        console.log("Error:", data);
      }
    } catch (error) {
      console.error("Error checking turno activo:", error);
    }
  }, [currentToken]);

  const fetchMozos = useCallback(async () => {
    try {
      const response = await fetch(
        "https://tippitqr.com/userdb/mozos_vinculados",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setMozos(data);
        const rolesFromMozos = data.map(grupo => grupo.rol);
        setRoles(rolesFromMozos);
      } else {
        console.log("Error fetching mozos:", data);
      }
    } catch (error) {
      console.error("Error fetching mozos:", error);
    }
  }, [currentToken]);

  useEffect(() => {
    let currentToken = location.state?.token;
    if (!currentToken) {
      currentToken = sessionStorage.getItem("token");
    } else {
      sessionStorage.setItem("token", currentToken);
    }

    if (!currentToken) {
      navigate("/");
    } else {
      setToken(currentToken);
    }
  }, [navigate, location.state]);

  const backupTurnos = async () => {
    if (token) {
      try {
        const response = await fetch(
          "https://tippitqr.com/checkear/backup-turnos",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ token: token.toString() }),
          }
        );

        const data = await response.json();

        if (response.status === 200) {
          setShowModal(true);
        } else if (response.status === 400) {
          console.log("No existing backups para el dia de hoy");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.status);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const TurnoButtonClick = async () => {
    await backupTurnos();
  };

  const finalizarTurno = async () => {
    try {
      setIsLoading(true);
      const mozosString = selectedMozos.join(",");
      const montoEfectivoFinal = montoEfectivo === "" ? 0 : montoEfectivo;

      // Crear efectivoTrabajadores
      const allMozosCuit = mozos.flatMap(grupo => grupo.usuarios.map(mozo => mozo.cuit));
      const efectivoTrabajadores = {};
      allMozosCuit.forEach(cuit => {
        if (selectedMozos.includes(cuit)) {
          efectivoTrabajadores[cuit] = parseFloat(efectivoValues[cuit]) || 0;
        } else {
          efectivoTrabajadores[cuit] = 0;
        }
      });

      if (process.env.NODE_ENV === "development") {
        console.log("Mozos:", mozosString);
        console.log("Monto Efectivo:", montoEfectivoFinal);
        console.log("Efectivo Trabajadores:", efectivoTrabajadores);
      }

      const bodyParams = {
        mozos: mozosString,
        monto_efectivo: montoEfectivoFinal,
      };

      if (efectivoEnabled) {
        bodyParams.efectivo_separado = JSON.stringify(efectivoTrabajadores);
      }

      const bodyData = new URLSearchParams(bodyParams).toString();

      const response = await fetch("https://tippitqr.com/finalizar/turno", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: bodyData,
      });

      const data = await response.json();

      if (response.status === 200) {
        setFinalizarResponse(data);
        setStatus(data.mensaje);
        setTurnoTiempo("");
        localStorage.removeItem("Turno");
        setShowModal(true);
        setError(null);
      } else {
        console.log(data);
        setError(`${data.error}`);
      }
    } catch (error) {
      setError(`Error al finalizar el turno: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkTurnoActivo();
  }, [checkTurnoActivo]);

  useEffect(() => {
    if (status === "Ya existe un turno activo") {
      fetchMozos();
    }
  }, [status, fetchMozos]);

  const crearTurno = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://tippitqr.com/crear/turno", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        body: new URLSearchParams({
          hora_fin: "12",
          backup: backup.toString(),
        }).toString(),
      });

      const data = await response.json();

      if (response.status === 200) {
        setStatus(data.status);
        setShowCreateForm(false);
        localStorage.setItem("Turno", new Date().toISOString());
        setTimeout(() => {
          setStatus(null);
          checkTurnoActivo();
        }, 500);
      } else {
        console.log("Error al crear el turno:", data);
      }
    } catch (error) {
      console.error("Error creando turno:", error);
    }
  };

  const handleMozosChange = (value, cuit) => {
    if (value) {
      setSwitchCount((prevCount) => prevCount + 1);
      setSelectedMozos((prevState) => [...prevState, cuit]);
    } else {
      setSwitchCount((prevCount) => prevCount - 1);
      setSelectedMozos((prevState) =>
        prevState.filter((item) => item !== cuit)
      );
    }
  };

  useEffect(() => {
    const calculateTimeElapsed = () => {
      const turnoInicio = localStorage.getItem("Turno");
      if (!turnoInicio) return;

      const turnoInicioDate = new Date(turnoInicio);
      const ahora = new Date();
      const diferencia = ahora - turnoInicioDate;

      const horas = Math.floor(diferencia / (1000 * 60 * 60));
      const minutos = Math.floor(
        (diferencia % (1000 * 60 * 60)) / (1000 * 60)
      );
      const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

      setTurnoTiempo(`${horas}h ${minutos}m ${segundos}s`);
    };

    calculateTimeElapsed();
    const interval = setInterval(calculateTimeElapsed, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSelectAllCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);
    if (isChecked) {
      const allCuits = mozos.flatMap(grupo =>
        grupo.usuarios.map(mozo => mozo.cuit)
      );
      setSelectedMozos(allCuits);
    } else {
      setSelectedMozos([]);
    }
  };

  const handleNameFilterChange = (e) => {
    setNameFilter(e.target.value);
  };

  const handleRoleFilterChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleEfectivoCheckboxChange = (newValue) => {
    setEfectivoEnabled(newValue);
    if (newValue) {
      setMontoEfectivo(""); 
    }
  };

  const handleEfectivoValueChange = (cuit, value) => {
    setEfectivoValues(prevValues => ({
      ...prevValues,
      [cuit]: value,
    }));
  };

  useEffect(() => {
    if (efectivoEnabled) {

      const total = Object.entries(efectivoValues)
        .filter(([cuit, value]) => value !== '' && !isNaN(value))
        .reduce((acc, [cuit, value]) => acc + parseFloat(value), 0);
      setMontoEfectivo(total.toFixed(2));
  
      setSelectedMozos((prevSelectedMozos) => {
        const cuitsWithValue = Object.entries(efectivoValues)
          .filter(([cuit, value]) => value !== '' && !isNaN(value))
          .map(([cuit, value]) => cuit);
  
        const newSelectedMozos = [...new Set([...prevSelectedMozos, ...cuitsWithValue])];

        if (newSelectedMozos.length !== prevSelectedMozos.length) {
          setSwitchCount(newSelectedMozos.length);
        }
  
        return newSelectedMozos;
      });
    }
  }, [efectivoValues, efectivoEnabled]);
  
  const isFinalizeButtonDisabled = () => {
    if (isLoading) return true;
    if (switchCount < 2) return true;

    if (efectivoEnabled) {
      for (let cuit of selectedMozos) {
        const value = efectivoValues[cuit];
        if (value === undefined || value === '' || isNaN(parseFloat(value))) {
          return true; 
        }
      }
      return false; 
    } else {
      if (montoEfectivo === '' || parseFloat(montoEfectivo) <= 0) {
        return true; 
      }
      return false;
    }
  };

  const addDollarSigns = (text) => {
    return text.replace(/\b(\d+(\.\d+)?)\b/g, "$$$1");
  };
  

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div className="flex items-left justify-left w-90 text-sm ">
        <div className="flex w-full">
          <Menu rol="Restaurante" token={token} />
          <div className="flex-1 w-full">
            <div
              className={`w-11/12 max-w-[900px] mb-10 pt-8 mt-10 px-8 pb-5 min-h-96 mx-auto bg-white shadow-lg shadow-gray-250 rounded-lg relative ${
                turnoTiempo
                  ? "border-solid border-2 border-red-500 max-w-[900px]"
                  : ""
              }`}
            >
              <h1 className="text-2xl font-semibold mb-2 flex items-center">
                Registro de Turnos
                <ToolTip position="bottom" width="w-80" text="Esta herramienta te permite registrar y finalizar turnos para calcular la propina a repartir entre los mozos en partes iguales y proporcionales." />
              </h1>
              <p className="text-md md:text-lg">
                Antes de comenzar un turno, primero debes tener que terminar cualquier turno que este corriendo en la actualidad.
                {/* esta para el culo escrito esto de aca arriba ^ coma incluida mal puesta. Readaccion a marzo. */}
              </p>
              <h3 className="text-md font-bold mt-3 md:absolute top-3 right-8">
                Turnos en progreso:
                <span
                  className={`rounded-md md:ml-2 font-normal block md:inline ${
                    status === "Ya hay un turno activo"
                      ? "text-red-500 "
                      : "text-green-500"
                  }`}
                >
                  {status}
                  <span
                    className={`bg-red-500 text-white rounded-md text-xl font-semibold px-3 md:ml-3 inline-block min-w-[140px] text-center ${
                      turnoTiempo ? "" : "hidden"
                    }`}
                  >
                    {turnoTiempo}
                  </span>
                </span>
              </h3>

              <hr className="my-5 mx-[-30px]" />
              {showCreateForm && (
                <div className="mt-4">
                  <h3 className="text-lg mb-2 font-semibold">Nuevo turno</h3>
                  <form onSubmit={crearTurno}>
                    <div className="flex flex-row space-x-4 mb-4">
                      <div className="w-50 mr-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Hora de cierre
                        </label>
                        <input
                          type="time"
                          name="hora_fin"
                          defaultValue="12:00"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          required
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="transition:all 
                            duration-300 
                            ease-in-out 
                            w-full 
                            px-2 
                            py-4 
                            text-white 
                            font-semibold 
                            text-lg
                            bg-[#FF5EA3] 
                            rounded-lg 
                            hover:bg-[#FF7DF2] 
                            p-3"
                      onClick={TurnoButtonClick}
                    >
                      Comenzar nuevo turno
                    </button>
                    <p className="font-normal text-center mt-2 text-sm text-gray-500">
                      El sistema no realiza transacciones, solo ayuda a
                      calcular y visualizar cuánto le corresponde a cada mozo
                    </p>
                  </form>
                </div>
              )}

              {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
                  <div className="bg-white pt-6 rounded-lg shadow-lg text-center w-6/12 max-w-96 min-w-[355px] p-4">
                    <h2 className="text-xl font-bold">
                      Transferencias Pendientes
                    </h2>
                    <p className="mb-4 text-md">
                      Existen transferencias pendientes. ¿Quieres agregarlas
                      también?
                    </p>
                    <div className="flex justify-center space-x-4">
                      <button
                        onClick={() => {
                          setBackup(true);
                          setShowModal(false);
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-[#FF5EA3] rounded-lg hover:bg-[#FF7DF2] hover:scale-105"
                      >
                        Sí, agregarlas
                      </button>
                      <button
                        onClick={() => {
                          setBackup(false);
                          setShowModal(false);
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-gray-500 rounded-lg hover:bg-gray-700 hover:scale-105"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {status && !showCreateForm && !finalizarResponse && (
                <div className="mt-4">
                  <h2 className="text-2xl mb-2 text-gray-600 font-semibold">
                    Finalizar turno activo
                  </h2>
                  <p className="text-md md:text-lg mb-5">
                    Antes de comenzar un turno, primero tenes que terminar
                    cualquier turno que esté corriendo en la actualidad.
                    Agrega el personal que trabajó en este turno para poder
                    finalizarlo.
                  </p>

                  {mozos.length > 0 ? (
                    <div className="overflow-visible">
                      <table className="table-auto w-full text-center">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Trabajador</th>
                            <th>Rol</th>
                            <th className="flex items-center justify-center text-center">
                              Efectivo
                              <ToolTip position="left" width="w-80" text="Activando esta opción, podrás determinar la cantidad de efectivo que registró cada trabajador (esta función es opcional)." />
                            </th>
                          </tr>
                          <tr className="bg-gray-100">
                            <td className="py-4">
                              <input
                                type="checkbox"
                                onChange={handleSelectAllCheckboxChange}
                                checked={selectAllChecked}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={nameFilter}
                                className="border p-2 shadow"
                                onChange={handleNameFilterChange}
                                placeholder="Buscar trabajador"
                              />
                            </td>
                            <td>
                              <select
                                className="p-1 shadow border"
                                value={selectedRole}
                                onChange={handleRoleFilterChange}
                              >
                                <option value="">Todos</option>
                                {roles.map((role) => (
                                  <option key={role} value={role}>
                                    {role}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                             <Switch 
                                onChange={handleEfectivoCheckboxChange} 
                                checked={efectivoEnabled} 
                              />
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {mozos.flatMap((grupo) =>
                            grupo.usuarios
                              .filter((mozo) =>
                                mozo.nombre
                                  .toLowerCase()
                                  .includes(nameFilter.toLowerCase())
                              )
                              .filter(
                                (mozo) =>
                                  selectedRole === "" || mozo.rol === selectedRole
                              )
                              .map((mozo) => (
                                <tr key={mozo._id} className="border-t">
                                  <td className="p-1.5">
                                    <input
                                      type="checkbox"
                                      id={`checkbox-${mozo.cuit}`}
                                      checked={selectedMozos.includes(
                                        mozo.cuit
                                      )}
                                      onChange={(e) =>
                                        handleMozosChange(
                                          e.target.checked,
                                          mozo.cuit
                                        )
                                      }
                                      className="form-checkbox"
                                    />
                                  </td>
                                  <td className="p-1.5">
                                    <div className="flex items-center">
                                      <img
                                        src={mozo.foto}
                                        onError={(e) => {
                                          e.currentTarget.src =
                                            "./Tippit-logo-cuadrado-trasparente-300x300.png";
                                        }}
                                        className="rounded-full object-cover w-10 h-10 border-3 mr-2"
                                        alt={mozo.nombre}
                                        draggable="false"
                                      />
                                      {mozo.nombre}
                                    </div>
                                  </td>
                                  <td className="p-1.5">{mozo.rol}</td>
                                  <td className="p-1.5">
                                    <input
                                      type="number"
                                      placeholder="$"
                                      className="border px-2 py-1 shadow hover:border-pink-500"
                                      value={efectivoValues[mozo.cuit] || ""}
                                      onChange={(e) =>
                                        handleEfectivoValueChange(
                                          mozo.cuit,
                                          e.target.value
                                        )
                                      }
                                      disabled={!efectivoEnabled}
                                      min="0"
                                      step="0.01"
                                    />
                                  </td>
                                </tr>
                              ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="w-full">
                      Por favor vincula mozos antes de utilizar esta
                      herramienta.
                    </p>
                  )}

                  <div className="mb-6 text-center">
                    <div className="flex items-center justify-center text-gray-500 text-lg mt-10 font-bold mb-2">
                      Monto en Efectivo
                      <ToolTip position="top" width="w-80" text="Ingresa en este campo el total de efectivo del turno y ¡listo! Tippit se encarga de calcular lo demás!" />
                    </div>

                    <input
                      type="number"
                      value={montoEfectivo}
                      min="0"
                      placeholder="$"
                      onChange={(e) => setMontoEfectivo(e.target.value)}
                      onFocus={(e) => e.target.select()}
                      disabled={efectivoEnabled}
                      className={`shadow appearance-none rounded w-60 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                        parseFloat(montoEfectivo) <= 0 && montoEfectivo !== ""
                          ? "border-red-500"
                          : "border"
                      }`}
                    />
                  </div>
                  <button
                    onClick={finalizarTurno}
                    disabled={isFinalizeButtonDisabled()}
                    className="transition-all 
                          duration-300 
                          ease-in-out 
                          w-full 
                          px-2 
                          py-4 
                          disabled:bg-gray-500
                          text-white 
                          font-semibold 
                          bg-red-500 
                          rounded-lg 
                          hover:bg-red-800 "
                  >
                    {!isLoading ? (
                      "Finalizar Turno"
                    ) : (
                      <img
                        src="/gooey-balls-2.svg"
                        className="mx-auto filter invert"
                        draggable="false"
                        alt="cargando"
                      />
                    )}
                  </button>
                  <p className="text-red-700 text-center mt-1">{error}</p>
                </div>
              )}

              {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50">
                  <div className="bg-white pt-6 rounded-lg shadow-lg text-left w-6/11 max-w-90 min-w-[355px] p-4">
                    <h3 className="text-lg mb-2 font-semibold">
                      Resultado del turno finalizado:
                    </h3>
                    <p className="text-gray-700 text-lg">
                      Monto Efectivo: ${finalizarResponse.Monto.efectivo}
                    </p>
                    <p className="text-gray-700 text-lg">
                      {finalizarResponse.detalles.map((detalle, index) => (
                        <span key={index} className="block">
                          {addDollarSigns(detalle)}
                        </span>
                      ))}
                    </p>
                    <div className="text-center">
                      <button
                        onClick={() => {
                          setShowModal(false);
                          window.location.reload();
                        }}
                        className="transition-all duration-300 ease-in-out px-4 py-2 text-white font-semibold bg-gray-500 rounded-lg hover:bg-gray-700 hover:scale-105 mt-4"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <CalendarTurnos />

              {sessionExpired && <SessionOut />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Turno;