// UseData.js

export const fetchData = async (year) => {
    const token = sessionStorage.getItem("token");
    try {
        const response = await fetch(`https://tippitqr.com/api/propinas/año-actual?a%C3%B1o_actual=${year}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        if (!response.ok) throw new Error('Error en la solicitud');
        const data = await response.json();
        return data.propinas_año_actual;
    } catch (error) {
        console.error('Error al obtener la data:', error.message);
    }
};


export const processAndCalculateData = (data) => {
    const result = {};
    
    data.forEach(item => {
        const mozo = item.propina.Mozo;
        const mes = item.mes;
        const calificacion = item.propina.calificacion;
        const monto = item.propina.monto;
        
        if (!mozo.id) {
            console.error('Falta el ID del mozo en el item:', item);
            return;
        }
        
        if (!result[mozo.id]) {
            result[mozo.id] = {
                id: mozo.id,
                nombre: mozo.nombre,
                apellido: mozo.apellido,
                rol: mozo.rol,
                meses: {}
            };
        }
        
        if (!result[mozo.id].meses[mes]) {
            result[mozo.id].meses[mes] = {
                totalCalificaciones: 0,
                totalMonto: 0,
                count: 0,
                tips: [] // Inicializa el array para las últimas 5 propinas
            };
        }
        
        const mesData = result[mozo.id].meses[mes];
        mesData.totalCalificaciones += calificacion;
        mesData.totalMonto += monto;
        mesData.count += 1;
        
        // Agregar la propina y mantener solo las últimas 5
        mesData.tips.push({ nombre: mozo.nombre, monto });
        if (mesData.tips.length > 5) {
            mesData.tips.shift(); // Mantener solo las últimas 5 propinassssss
        }
    });
    
    // Calcular promedios
    Object.keys(result).forEach(id => {
        const mozo = result[id];
        Object.keys(mozo.meses).forEach(mes => {
            const mesData = mozo.meses[mes];
            if (mesData.count > 0) {
                mesData.promedioCalificacion = Math.floor(mesData.totalCalificaciones / mesData.count);
                mesData.promedioMonto = Math.floor(mesData.totalMonto / mesData.count);
            } else {
                mesData.promedioCalificacion = 0;
                mesData.promedioMonto = 0;
            }
        });
    });
    
    console.table(result);
    return Object.values(result);
};
