// utils/Modal.js

import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';
import {
    XCircleFill
  } from "react-bootstrap-icons";


const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content mx-5 max-w-[80vw] min-w-[320px]" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}><XCircleFill /></button>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;
