import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Menu from "../components/Menu";
import SessionOut from "../components/SessionOut";
import { QrCode, StarFill } from "react-bootstrap-icons";
import {
  fetchData,
  processAndCalculateData,
} from "../utils/MiPersonal/UseData";
import {
  createIndexedDB,
  saveToIndexedDB,
  doesIndexedDBExist,
  getFromIndexedDB,
} from "../utils/MiPersonal/IndexedDB";

const StatCard = ({ title, children }) => (
  <div className="border border-gray-300 rounded-lg py-3 px-5 text-center text-gray-500 flex-grow transition-shadow duration-300 hover:shadow-lg group hover:border-pink-500">
    <h2 className="text-xl font-bold text-gray-500 group-hover:text-pink-500 transition-colors duration-300">
      {title}
    </h2>
    <div className="relative overflow-hidden">
      <div className="absolute inset-0 bg-white flex items-center justify-center fade-out z-10">
        <div className="text-2xl text-gray-500">
          <img
            src="/gooey-balls-2.svg"
            className="mx-auto filter invert"
            draggable="false"
            alt=""
          />
        </div>
      </div>
      {children}
    </div>
  </div>
);

const MiPersonal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [sessionExpired, setSessionExpired] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [period, setPeriod] = useState("Últimos 30 días");
  const [totalAmount, setTotalAmount] = useState(0);
  const [averageTip, setAverageTip] = useState(0);
  const [averageService, setAverageService] = useState(0);
  const [totalTipsCount, setTipsCount] = useState(0);
  const [lastFiveTips, setLastFiveTips] = useState([]);
  const [mozos, setMozos] = useState([]);
  const [selectedMozo, setSelectedMozo] = useState(null);

  useEffect(() => {
    const fetchAndGroupData = async () => {
      const existsInIndexedDB = await doesIndexedDBExist();
      let dataFromDB;

      if (!existsInIndexedDB) {
        console.log("Fetching data from API...");
        const fetchedData = await fetchData(year);
        console.log("Data fetched:", fetchedData);
        if (fetchedData) {
          const processedData = processAndCalculateData(fetchedData);
          console.log("Datos procesados:", processedData);
          await saveToIndexedDB(processedData);
          dataFromDB = processedData;
        } else {
          console.log("No se obtuvieron datos.");
          return;
        }
      } else {
        console.log("MiPersonal ya existe en IndexedDB. Obteniendo datos...");
        dataFromDB = await getFromIndexedDB();
      }

      if (dataFromDB) {
        const groupedData = dataFromDB.reduce((acc, item) => {
          const { nombre, rol, meses } = item;
          if (!acc[rol]) acc[rol] = [];
          acc[rol].push({ nombre, meses });
          return acc;
        }, {});

        const entries = Object.entries(groupedData);
        setSelectOptions(entries);

        if (entries.length > 0) {
          setSelectedValue(entries[0][1][0].nombre);
        }
      }
    };

    fetchAndGroupData();
  }, [year]);

  useEffect(() => {
    let currentToken = location.state?.token || sessionStorage.getItem("token");
    if (!currentToken) navigate("/");
    else {
      sessionStorage.setItem("token", currentToken);
      setToken(currentToken);
    }
  }, [navigate, location.state]);

  useEffect(() => {
    const calculateTotals = () => {
      if (selectOptions.length > 0 && selectedValue) {
        let totalAmount = 0;
        let totalTipsCount = 0;
        let totalRating = 0;
        let totalRatingsCount = 0;
        let lastFiveTips = [];
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        selectOptions.forEach(([role, names]) => {
          const selectedPerson = names.find(
            ({ nombre }) => nombre === selectedValue
          );
          if (selectedPerson) {
            const { meses } = selectedPerson;

            switch (period) {
              case "Últimos 30 días":
                const daysAgo = new Date();
                daysAgo.setDate(currentDate.getDate() - 30);
                const monthAgo = daysAgo.getMonth() + 1;
                const yearAgo = daysAgo.getFullYear();
                Object.keys(meses).forEach((mes) => {
                  const { totalMonto, count, promedioCalificacion, tips } =
                    meses[mes];
                  console.log(mes)
                  if (Number(mes) >= monthAgo && currentYear >= yearAgo) {
                    if (totalMonto > 0) {
                      totalAmount += totalMonto;
                      totalTipsCount += count;
                    }
                    if (promedioCalificacion > 0) {
                      totalRating += promedioCalificacion * count;
                      totalRatingsCount += count;
                    }
                    if (tips && tips.length > 0) {
                      lastFiveTips = lastFiveTips.concat(tips.slice(-5));
                    }
                    console.log("totalMonto: ", totalAmount)
                    console.log("promedioCalificacion: ", totalRating)
                    console.log("tipsCount: ", totalTipsCount)
                    console.log("ratingCoutn: ", totalRatingsCount)
                    //console.log(tips)
                  }
                });
                break;

              case "Últimos 3 meses":
                Object.keys(meses).forEach((mes) => {
                  const { totalMonto, count, promedioCalificacion, tips } =
                    meses[mes];
                  if (Number(mes) >= currentMonth - 2) {
                    if (totalMonto > 0) {
                      totalAmount += totalMonto;
                      totalTipsCount += count;
                    }
                    if (promedioCalificacion > 0) {
                      totalRating += promedioCalificacion * count;
                      totalRatingsCount += count;
                    }
                    if (tips && tips.length > 0) {
                      lastFiveTips = lastFiveTips.concat(tips.slice(-5));
                    }
                  }
                });
                break;

              case "Últimos 6 meses":
                Object.keys(meses).forEach((mes) => {
                  const { totalMonto, count, promedioCalificacion, tips } =
                    meses[mes];
                  if (Number(mes) >= currentMonth - 5) {
                    if (totalMonto > 0) {
                      totalAmount += totalMonto;
                      totalTipsCount += count;
                    }
                    if (promedioCalificacion > 0) {
                      totalRating += promedioCalificacion * count;
                      totalRatingsCount += count;
                    }
                    if (tips && tips.length > 0) {
                      lastFiveTips = lastFiveTips.concat(tips.slice(-5));
                    }
                  }
                });
                break;

              case "Último año":
                Object.keys(meses).forEach((mes) => {
                  const { totalMonto, count, promedioCalificacion, tips } =
                    meses[mes];
                  if (Number(mes) >= currentMonth - 11) {
                    if (totalMonto > 0) {
                      totalAmount += totalMonto;
                      totalTipsCount += count;
                    }
                    if (promedioCalificacion > 0) {
                      totalRating += promedioCalificacion * count;
                      totalRatingsCount += count;
                    }
                    if (tips && tips.length > 0) {
                      lastFiveTips = lastFiveTips.concat(tips.slice(-5));
                    }
                  }
                });
                break;

              default:
                break;
            }
          }
        });

        lastFiveTips = lastFiveTips.slice(-5);

        const averageTip =
          totalTipsCount > 0 ? totalAmount / totalTipsCount : 0;
        const averageService =
          parseFloat(totalRatingsCount > 0
            ? (totalRating / totalRatingsCount).toFixed(1)
            : 0);
        setTotalAmount(totalAmount);
        setAverageTip(averageTip);
        setAverageService(averageService.toFixed(2)); // Ensure it's a number
        setTipsCount(totalTipsCount);
        console.log(lastFiveTips);
        setLastFiveTips(lastFiveTips); // Set the last five tips
      }
    };

    calculateTotals();
  }, [selectOptions, selectedValue, period]);

  useEffect(() => {
    if (mozos.length > 0 && selectedValue) {
      const mozoData = mozos.find((mozo) => mozo.nombre === selectedValue);
      setSelectedMozo(mozoData);
    }
  }, [mozos, selectedValue]);

  const fetchMozos = useCallback(async () => {
    try {
      const response = await fetch(
        "https://tippitqr.com/listar_QR_pagos",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 401) {
        // Manejar sesión expirada si es necesario
        return;
      }

      const data = await response.json();

      if (Array.isArray(data.qr_pagos)) {
        const filteredData = data.qr_pagos.map(({ mesa, mozo }) => ({
          nombre: mozo.nombre,
          mesa: mesa,
          foto: mozo.foto,
          fin_atencion: mozo.fin_atencion ? "INACTIVO" : "ACTIVO",
        }));

        console.table(filteredData); // Imprimir en consola para depuración
        setMozos(filteredData); // Guardar datos en el estado
      }
    } catch (error) {
      console.error("Error accediendo a QR pagos:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchMozos();
  }, [fetchMozos]);

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-pink-500 via-pink-200 to-gray-300 md:bg-none">
      <div className="flex flex-col h-screen">
        <div className="relative block bg-gray-100">
          <div className="flex flex-1 overflow-auto">
            <Menu rol="Restaurante" token={token} />
            <div className="flex-1 w-full">
              <h1 className="block md:hidden text-xl bg-pink-500 text-white p-2 font-sans mt-0 pt-1 pl-10 mb-4">
                Mi Personal
              </h1>
              <div className="w-11/12 max-w-[900px] pt-8 lg:mt-10 px-8 pb-10 min-h-96 mx-auto bg-white overflow-hidden shadow-lg shadow-gray-250 rounded-lg relative mb-10">
                <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start">
                  <div className="lg:w-1/2 mb-4 lg:mb-0">
                    <h1 className="text-3xl font-semibold mb-2 lg:mb-2">
                      Mi Personal
                    </h1>
                    <p className="text-md md:text-lg">
                      Todos tus empleados en un solo lugar.
                    </p>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:w-2/3 lg:justify-end">
                    <select
                      value={selectedValue}
                      onChange={(e) => setSelectedValue(e.target.value)}
                      className="w-auto mb-4 lg:mb-0 lg:mr-4 py-2 px-3 rounded border transition-all hover:shadow-md"
                    >
                      {selectOptions.map(([role, names]) => (
                        <optgroup key={role} label={role}>
                          {names.map(({ nombre }, index) => (
                            <option key={index} value={nombre}>
                              {nombre}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </select>
                    <select
                      value={period}
                      onChange={(e) => setPeriod(e.target.value)}
                      className="w-auto py-2 px-3 rounded border transition-all hover:shadow-md"
                    >
                      <option value="Últimos 30 días">Últimos 30 días</option>
                      <option value="Últimos 3 meses">Últimos 3 meses</option>
                      <option value="Últimos 6 meses">Últimos 6 meses</option>
                      <option value="Último año">Último año</option>
                    </select>
                  </div>
                </div>

                <hr className="my-5 mx-[-30px]" />

                <div className="flex flex-wrap gap-4">
                  <div className="w-full lg:w-[38%] flex">
                    <StatCard title="Propina" className="flex-grow">
                      <p className="text-5xl">${totalAmount.toFixed(2)}</p>
                    </StatCard>
                  </div>
                  <div className="w-full lg:w-[18%] flex">
                    <StatCard title="Cantidad" className="flex-grow">
                      <p className="text-4xl mt-1">{totalTipsCount}</p>
                    </StatCard>
                  </div>
                  <div className="w-full lg:w-[20%] flex">
                    <StatCard title="Promedio" className="flex-grow">
                      <p className="text-4xl mt-1">${averageTip.toFixed(2)}</p>
                    </StatCard>
                  </div>
                  <div className="w-full lg:w-[18%] flex">
                    <StatCard title="Servicio" className="flex-grow">
                      <div className="flex items-center justify-center">
                        <StarFill className="text-pink-500 text-2xl mr-2  mt-1 " />
                        {averageService !== "0.00" ? (
                          <p className="text-3xl mt-1 font-bold">
                            {averageService}
                          </p>
                        ) : (
                          <p className="text-l mt-1 font-bold">
                            {"Data insuficiente"}
                          </p>
                        )}
                      </div>
                    </StatCard>
                  </div>

                  <div className="w-[33.3333%] flex-shrink-0">
                    <StatCard
                      title="Ultimas"
                      className="flex-grow flex items-center justify-center"
                    >
                      <ol className="list-none text-left pl-0">
                        {lastFiveTips.map((tip, index) => (
                          <li
                            key={index}
                            className="flex items-center mb-2 rounded border w-full pl-3 py-1"
                          >
                            <span className="text-pink-500 font-bold text-2xl mr-2 text-center w-4">
                              {index + 1}
                            </span>
                            <span className="text-md">{`Propina de`}</span>{" "}
                            <span className="ml-3 font-bold">
                              ${tip.monto.toFixed(2)}
                            </span>
                          </li>
                        ))}
                      </ol>
                    </StatCard>
                  </div>
                  <div className="w-[64.5%] flex-shrink-0 flex items-center justify-center bg-gray-100 rounded-lg py-4">
                    {selectedMozo ? (
                      <div
                        className={`px-4 py-1 rounded-lg border-4 w-60 transition-all shadow-lg hover:shadow-gray-500 ${selectedMozo.fin_atencion.trim().toLowerCase() === 'activo' ? 'bg-white border-green-600' : 'bg-white border-red-600'
                          }`}
                      >
                        <div className="text-center">
                          <div className="flex items-center justify-center mt-2">
                            <QrCode />
                            <h2 className="text-2xl ml-2">{selectedMozo.mesa}</h2>
                          </div>
                          <p className={`rounded text-white mt-2 ${selectedMozo.fin_atencion.trim().toLowerCase() === 'activo' ? 'bg-green-700' : 'bg-red-700'}`}>
                            {selectedMozo.fin_atencion}
                          </p>
                          <img
                            src={selectedMozo.foto}
                            alt={selectedMozo.nombre}
                            className="w-32 h-32 object-cover rounded-full mt-2 mx-auto"
                          />
                          <p className="text-xl font-bold mt-2 pb-3">{selectedMozo.nombre}</p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-center">Esta persona no está asignad@ a un QR.<br />Puedes hacerlo <a href="./misqrs" className="text-pink-600 underline">desde acá</a>.</p>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
          {sessionExpired && <SessionOut />}
        </div>
      </div>
    </div>
  );
};

export default MiPersonal;
