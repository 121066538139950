import { CupHotFill } from "react-bootstrap-icons";

export default function Payments(props){
    const { paymentIndex, activeButton, activeSubButton, mesaDetails, payment, cantPayments, pos } = props
    return (
      <div key={paymentIndex}>
        <div
          className={`flex flex-row items-center text-sm ${
            activeButton !== "Mesa" ? "py-5" : "py-3"
          } ${
            paymentIndex !== cantPayments - 1 ? "border-b border-gray-200" : ""
          }`}
        >
          {activeButton !== "Mesa" && (
            <div className="xs:w-1/6 md:w-1/6 relative md:transform md:scale-[1.25]">
              <div className="absolute w-full h-full flex items-end justify-center text-lg text-white font-mono ml-[-3px]">
                {payment.mesa}
              </div>

              <CupHotFill size={42} color="#f05e9e" className="mx-auto" />
            </div>
          )}

          <div className="w-20">
            <img
              src={
                activeButton === "Mesa"
                  ? mesaDetails[pos]?.photo
                  : payment.Mozo.photo || "profile_she.jpg"
              }
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop if fallback image fails
                e.target.src = "Tippit-logo-cuadrado-trasparente-300x300.png";
              }}
              className="img-fluid rounded-full shadow w-10 h-10 md:w-12 md:h-12 mx-auto border-pink-500 border-2"
              alt=""
            />
          </div>
          <div className="w-60 text-gray-600 pl-3">
            {payment.estado === "approved" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    payment.estado_detallado === "accredited"
                      ? "Propina Acreditada <span className='text-green-600'>✔</span>"
                      : "Propina Pendiente",
                }}
              />
            ) : (
              <div>Propina pendiente</div>
            )}

            <div className="text-md text-gray-500 capitalize">
              {payment.Mozo.nombre} {payment.Mozo.apellido}
            </div>
            <div className="text-xs text-gray-400 capitalize">
              {payment.Restaurante}
            </div>

            {activeSubButton === "UltimoAnio" && (
              <div className="text-xs text-gray-400">
                {new Date(payment.hora_aprovacion_pago).toLocaleDateString(
                  "es-AR",
                  {
                    month: "long",
                    day: "numeric",
                  }
                )}
              </div>
            )}

            <div className="flex mt-1">
              {Array.from({ length: 5 }, (_, index) => (
                <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill={
                    index < payment.calificacion ? "currentColor" : "lightgray"
                  }
                  className="bi bi-star-fill"
                  viewBox="0 0 16 16"
                  style={{ marginRight: "2px" }}
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
              ))}
            </div>
          </div>
          <div className="w-1/2 md:w-full md:text-xl text-right pr-5 bg-transparent">
            <span className="text-green-700">+${payment.monto.toFixed(2)}</span>
            <br />
            <span className="text-gray-400 text-xs">
              {/* Esta es la hora GTM-3 pero le resto tres horas porque mercadopago lo envia con GTM (3 horas despues) de aprobado */}
              {new Date(
                new Date(payment.hora_aprovacion_pago).getTime() -
                  3 * 60 * 60 * 1000
              ).toLocaleString("es-AR", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}
            </span>
          </div>
        </div>
      </div>
    );
}